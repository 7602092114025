<template>
  <v-container fluid>
    <Header headerClass="politiche" title="Politiche per l'aria" />
    <v-container page id="content">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Reti di collaborazione</h2>
          <v-card class="c-left" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>
                  Accordo di bacino padano per il miglioramento della qualità
                  dell’aria
                </h3>
                <p class="mt-40">
                  L'Accordo di Bacino Padano per l’attuazione di misure
                  congiunte per il miglioramento della qualità dell’aria è stato
                  sottoscritto a Bologna, durante il G7 Ambiente del 9 giugno
                  2017, dal Ministro per l’Ambiente e dai Presidenti di Regione
                  Lombardia, Piemonte, Veneto e Emilia-Romagna.
                </p>
                <p class="mt-40">
                  Le <strong>misure individuate</strong> - strutturali e
                  temporanee - sono rivolte prioritariamente al settore traffico
                  (limitazioni veicoli diesel), ai generatori di calore
                  domestici a legna, alle combustioni all'aperto e al
                  contenimento delle emissioni di ammoniaca dalle attività
                  agricole e zootecniche.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="text-center">
            <a
              target="_blank"
              href="http://www.regione.piemonte.it/pinforma/images/DOCUMENTI/accordo-bacino-padano.pdf"
              class="v-btn primary"
            >
              <span class="v-btn__content">
                TESTO DELL'ACCORDO
                <i class="v-icon notranslate mdi mdi-download"></i>
              </span>
            </a>
          </div>
          <v-card class="c-right" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>Reti Internazionali</h3>
                <p class="mt-40">
                  Regione Piemonte si impegna a portare avanti progetti
                  strategici in stretta collaborazione con altre realtà
                  territoriali, aderendo a molteplici Reti di Regioni europee.
                </p>
                <p class="mt-40">
                  Lungo il corso degli anni, infatti, si è assistito a un
                  <strong>consolidamento di questi gruppi di Regioni</strong>
                  che, insieme, hanno ottenuto una rilevanza sempre maggiore
                  all’interno del processo decisionale comunitario.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="reti-collaborazione">
            <p>
              Di seguito le principali Reti di Regioni alle quali partecipa
              Regione Piemonte:
            </p>
            <div class="lista">
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://airregions.wordpress.com/"
                    title="Questo link si apre in una nuova scheda"
                    >AIR - Air quality Initiative of Regions
                    <i class="fas fa-external-link-alt" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.under2coalition.org/"
                    title="Questo link si apre in una nuova scheda"
                    >Under2 coalition
                    <i class="fas fa-external-link-alt" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.alpine-region.eu/"
                    title="Questo link si apre in una nuova scheda"
                    >EUSALP - EU Strategy for the Alpine region
                    <i class="fas fa-external-link-alt" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://egtc-rhine-alpine.eu/it/"
                    title="Questo link si apre in una nuova scheda"
                    >GECT - Gruppo Europeo di Cooperazione Territoriale sullo
                    sviluppo del corridoio reno-alpi
                    <i class="fas fa-external-link-alt" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuPolitiche page="reti-collaborazione" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuPolitiche from "@/views/politiche/MenuPolitiche";

export default {
  components: {
    Header,
    MenuPolitiche
  }
};
</script>
